import {FC} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {ICountry} from '../../../core/_models'
import flagsData from "../../../../../../../data/general/flags.json";

type Props = {
  country: ICountry
}

const CountryIconCell: FC<Props> = ({country}) => {
  const flag = flagsData.find((item) => item.country === country.country)
  return (
    <div className='d-flex align-items-center'>
      {flag ? <img
          src={toAbsoluteUrl(flag.flag)}
          width={'20px'}
          alt=''
          className={`svg-icon-3 FlagsDirectionBar__flag ms-2`}
        /> : '-'
      }
    </div>
  )
}

export {CountryIconCell}
