import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ICountry} from "../../countries-list/core/_models";

interface Props {
  country: ICountry
}

const CountryHeaderLangSwitcher = ({country}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const availableLangs = ['uk', 'ru', 'en']

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <ul className='nav'>
      {availableLangs.map((locale) => (
        <li className='nav-item' key={locale}>
          <button
            type='button'
            onClick={() => {
              navigate(`/states/edit/${currTab}/${locale}/${country.id}`)

              setActiveTab(locale)
            }}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
              activeTab === locale ? 'active' : ''
            } fw-bold px-4`}
          >
            {locale}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default CountryHeaderLangSwitcher
