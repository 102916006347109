import {useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG, ListViewContextProps, QUERIES} from '../../../../../_metronic/helpers'
import {AddTrackForm, ITrackCodeStatus} from '../../../../pages/tracks/core/_models/_tracks-models'
import currenciesLocal from '../../../../../data/general/currencies.json'
import {TCurrencyData} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  trackNumber: string
  trackCodeStatus: ITrackCodeStatus
  isLoading: boolean
  isValid: boolean
  asTrack: boolean

  listView: ListViewContextProps
}

const OnePageModalHeader = ({
  trackNumber,
  asTrack,
  listView,
  trackCodeStatus,
  isLoading,
  isValid,
}: Props) => {
  const {values} = useFormikContext<AddTrackForm>()

  const headingIntl = listView.itemIdForUpdate
    ? asTrack === true
      ? 'TRACKS.ADD_MODAL.HEADER.EDIT'
      : values.sendAsBuyout
      ? 'BUYOUTS.ADD_MODAL.HEADER.EDIT'
      : 'ORDERS.ADD_MODAL.HEADER.EDIT'
    : asTrack === true
    ? 'TRACKS.ADD_MODAL.HEADER.CREATE'
    : values.sendAsBuyout
    ? 'BUYOUTS.ADD_MODAL.HEADER.CREATE'
    : 'ORDERS.ADD_MODAL.HEADER.CREATE'

  const prodsTotalCurrValue = values.prods.reduce((acc, i) => acc + +i.total, 0)

  const minBuyotPrice = values?.direction?.minBuyoutPrice

  const totalIsLessThanMin = minBuyotPrice && prodsTotalCurrValue < minBuyotPrice

  const currSymbols: TCurrencyData = currenciesLocal

  const buyoutCurrSymbol =
    values.direction.buyoutCurrency &&
    currenciesLocal.hasOwnProperty(values.direction.buyoutCurrency)
      ? `${currSymbols[values.direction.buyoutCurrency].symbol}`
      : ''

  return (
    <div
      className='modal-header px-6 py-2 position-fixed top-0 w-100 w-md-500px d-flex flex-column'
      style={{zIndex: 1, overflowX: 'hidden'}}
    >
      <div className='d-flex justify-content-between w-100'>
        {' '}
        <h2 className='fw-bold fs-3 p-3'>
          <FormattedMessage id={headingIntl} values={{trackNumber: ''}} />
          <p className='fw-normal fs-6 m-0 mt-1'>{trackNumber ? `${trackNumber}` : ''}</p>
        </h2>
        <div className='d-flex flex-gutter'>
          <Button
            trackNumber={trackNumber}
            asTrack={asTrack}
            listView={listView}
            trackCodeStatus={trackCodeStatus}
            isLoading={isLoading}
            isValid={isValid}
          />
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
            // data-kt-users-modal-action='close'
            onClick={() => {
              listView.setItemIdForUpdate(undefined)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
      </div>
      {values.sendAsBuyout && (minBuyotPrice || minBuyotPrice === 0) && totalIsLessThanMin && (
        <p className='fs-8 mt-2 mb-0 StatusBar__status bg-gray-200 shadow-xs'>
          <FormattedMessage
            id='BUYOUTS.MODAL.HEADER.MIN_PRICE_TOOLTIP'
            values={{
              min: `${buyoutCurrSymbol}${minBuyotPrice}`,
              curr: `${buyoutCurrSymbol}${prodsTotalCurrValue}`,
            }}
          />
        </p>
      )}
    </div>
  )
}

export {OnePageModalHeader}

const Button = ({trackCodeStatus, isLoading, listView}: Props) => {
  const {values} = useFormikContext<AddTrackForm>()

  const prodsTotalCurrValue = values.prods.reduce((acc, i) => acc + +i.total, 0)

  const minBuyotPrice = values?.direction?.minBuyoutPrice

  const totalIsLessThanMin = minBuyotPrice && prodsTotalCurrValue < minBuyotPrice

  const buyoutClass =
    minBuyotPrice && values.sendAsBuyout ? (totalIsLessThanMin ? 'bg-danger' : 'bg-success') : ''

  const currSymbols: TCurrencyData = currenciesLocal

  const buyoutCurrSymbol =
    values.direction.buyoutCurrency &&
    currenciesLocal.hasOwnProperty(values.direction.buyoutCurrency)
      ? `${currSymbols[values.direction.buyoutCurrency].symbol}`
      : ''

  const buyoutSumLine = `${buyoutCurrSymbol}${prodsTotalCurrValue}/${buyoutCurrSymbol}${minBuyotPrice}`
  return (
    <>
      {' '}
      {!listView.itemIdForUpdate && (
        <div>
          {trackCodeStatus.id && trackCodeStatus.id !== '' ? (
            <Link to={`/tracks/edit/overview/${trackCodeStatus.id}`}>
              <button type='button' className='btn btn-sm btn-primary minify-button fs-7 fs-md-5'>
                <FormattedMessage id='GENERAL.BUTTONS.EDIT' />
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
                />
              </button>
            </Link>
          ) : (
            <button
              type='submit'
              disabled={
                !!listView.itemIdForUpdate || isLoading === true || totalIsLessThanMin
                  ? true
                  : false
              }
              className={`btn btn-sm btn-primary me-3 minify-button fs-7 fs-md-5 ${buyoutClass}`}
            >
              <span className='indicator-label'>
                {isLoading === true ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    {minBuyotPrice && values.sendAsBuyout && (
                      <span className='me-2'>{buyoutSumLine}</span>
                    )}

                    <FormattedMessage
                      id={
                        listView.itemIdForUpdate ? 'GENERAL.BUTTONS.SAVE' : 'GENERAL.BUTTONS.CREATE'
                      }
                    />
                  </>
                )}
              </span>
            </button>
          )}
        </div>
      )}{' '}
      {listView.itemIdForUpdate && (
        <Link to={`/tracks/edit/overview/${listView.itemIdForUpdate}`}>
          <button type='button' className='btn btn-sm btn-primary minify-button fs-7 fs-md-5'>
            <FormattedMessage id='GENERAL.BUTTONS.EDIT' />
          </button>
        </Link>
      )}
    </>
  )
}
