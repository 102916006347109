import React, {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components'
import {CreateCollectionModalHeader} from './CreateCollectionModalHeader'
import {createCollectionSchema} from '../yup/collection'
import {ICollection, ICreateCollectionForm} from '../_models'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IWebsite} from '../../websites/core/_models'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import ScrollToFieldError from './ScrollToFieldError'
import {
  ListViewContextProps,
  QUERIES,
  QueryResponseContextProps,
} from '../../../../_metronic/helpers'
import WebsitesSelect from '../../../modules/custom/form-elements/selects/WebsitesSelect'

export type TProps = {
  listView: ListViewContextProps
  queryResponse: QueryResponseContextProps<ICollection>
  addCollectionRequest: (o: any) => any
}

const CreateCollectionForm = ({listView, queryResponse, addCollectionRequest}: TProps) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const {setItemIdForUpdate} = listView
  const {selectedLang} = getConfig()

  const {refetch} = queryResponse

  const inits: ICreateCollectionForm = {
    name: '',
    website:
      websitesData && websitesData[0]
        ? {value: websitesData[0]?.id, label: websitesData[0].name}
        : {value: '', label: ''},
    items: [],
    fromCategory: 0
  }

  const [initValues] = useState<ICreateCollectionForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  //mutation
  const createCollectionMutation = useMutation(addCollectionRequest, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)

      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (
    values: ICreateCollectionForm,
    formikHelpers: FormikHelpers<ICreateCollectionForm>
  ) => {
    const website = websitesData?.find((item) => item.id === values.website.value)
    let translations = {
      [selectedLang]: {
        name: '',
      },
    }
    if (website) {
      translations = {}

      website?.enabledLocales.forEach((locale) => {
        translations[locale] = {
          name: values.name,
        }
      })
    }
    createCollectionMutation.mutate({
      ...values,
      fromCategory: !!values.fromCategory,
      website: {id: values.website.value || initValues.website.value},
      translations,
    })
    formikHelpers.resetForm()
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={createCollectionSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_article_form'>
            <ScrollToFieldError />

            <CreateCollectionModalHeader
              isLoading={isLoading}
              isValid={isValid}
              listView={listView}
              queryResponse={queryResponse}
              addCollectionRequest={addCollectionRequest}
            />
            <div
              className='scroll-y modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                {/*{JSON.stringify(errors)}*/}
                {/*{JSON.stringify(values)}*/}
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <WebsitesSelect/>
                  </div>
                  <div className='fv-row mb-5'>
                    {' '}
                    <InputTemplate
                      inputName='name'
                      type='text'
                      required={true}
                      title={intl.formatMessage(
                        {id: 'WEBSITE_COLLECTIONS.LIST.ADD_MODAL.FORM.TITLE_LABEL'},
                        {
                          locale: websitesData?.find((item) => item.id === values.website.value)
                            ? `(${
                              websitesData?.find((item) => item.id === values.website.value)
                                ?.mainLocale
                            })`
                            : '',
                        }
                      )}
                      titleFontSize='fs-5 text-dark'
                    />
                  </div>

                  <div className='fv-row mb-5'>
                    <label className='col-form-label fw-bold fs-6'>Тип</label>
                    <InputTemplate
                      fieldWidth='col'
                      inputName='fromCategory'
                      required={false}
                      type='radio'
                      value={1}
                      title={intl.formatMessage({
                        id: 'GENERAL.ASIDE_MENU.CONTENT_CATEGORY_TITLE',
                      })}
                      titleFontSize='fs-5 text-dark fw-bold'
                      checkboxProps={{
                        type: 'radio',
                        inputName: 'fromCategory',
                        setFieldValue: setFieldValue,
                        disabled: false,
                        checked: !!values.fromCategory,
                        value: 1,
                      }}
                    />
                    <InputTemplate
                      fieldWidth='col'
                      inputName='fromCategory'
                      required={false}
                      type='radio'
                      value={0}
                      title={intl.formatMessage({
                        id: 'WEBSITE_COLLECTIONS.TYPE.CUSTOM',
                      })}
                      titleFontSize='fs-5 text-dark fw-bold'
                      checkboxProps={{
                        type: 'radio',
                        inputName: 'fromCategory',
                        setFieldValue: setFieldValue,
                        disabled: false,
                        checked: !values.fromCategory,
                        value: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateCollectionForm}
