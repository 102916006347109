import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {
  AddTrackForm,
  ICreateNewTrack,
  IInvoice,
  IRecentInvoiceRes,
  ITrackFormProd,
  Track,
} from '../../../../pages/tracks/core/_models/_tracks-models'
import {createTrackOnePageSchema} from '../../../../pages/tracks/core/yup/track'
import {InputTemplate} from '../../form-elements/InputTemplate'
import {TrackGoods} from '../modules/goods/TrackGoods'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useQueryResponse} from '../../../../pages/tracks/core/QueryResponseProvider'
import {addTrack} from '../../../../pages/tracks/core/_requests'
import {TrackCodeField} from '../modules/general/TrackCodeField'
import {useIntl} from 'react-intl'
import {TrackInvoicesTab} from '../modules/invoices/TrackInvoicesTab'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import {TrackReceiverInput} from '../modules/TrackReceiverInput'
import {OnePageModalHeader} from './OnePageModalHeader'
import ScrollToFieldError from './ScrollToFieldError'
import {Direction} from '../../../../pages/directions/core/_models'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {addOrder} from '../../../../pages/orders/core/_requests'
import {
  ListViewContextProps,
  QUERIES,
  QueryResponseContextProps,
} from '../../../../../_metronic/helpers'
import {ClientSelect} from '../../../../pages/fulfillment/receivers/elements/ClientSelect'
import {ICreateNewOrder, IOrder} from '../../../../pages/orders/core/_models/_orders-models'
import {useLocation} from 'react-router-dom'
import {reformTrackFormProductsToApi} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import DirectionsSelect from '../../form-elements/selects/DirectionsSelect'

interface Props {
  track?: Track
  goodsFields?: ITrackFormProd[]
  directions: (IBasicSelect & Partial<Direction>)[]
  listView: ListViewContextProps
  queryResponse: QueryResponseContextProps<Track | IOrder>
}

const CreateTrackOnePageForm = ({
  track,
  goodsFields,
  directions,
  listView,
  queryResponse,
}: Props) => {
  // service
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {refetch} = queryResponse
  const {usersLoading, setUsersLoading, usersResults, setUsersResults, filterUsers, currPage} =
    listView
  const {pathname} = useLocation()

  // form fields data

  const receiversListData: IBasicSelect[] = queryClient.getQueryData(QUERIES.RECEIVERS_LIST) || []
  const recentInvoicesData: IRecentInvoiceRes[] =
    queryClient.getQueryData(QUERIES.TARIFFS_LIST) || []
  const [invoiceArray, setInvoiceArray] = useState<IInvoice[] | []>(
    track && track.invoice_file ? [...track.invoice_file] : []
  )

  const defFields: ITrackFormProd[] = [
    {
      category: {label: '', value: ''},
      qty: 1,
      cost: '',
      total: '',
    },
  ]

  if (currPage === 'buyouts') {
    defFields[0].url = ''
  }


  const inits: AddTrackForm = {
    direction: directions[0] || null,
    user: {value: null, label: null},
    sendAsTrack:
      pathname.split('/')[1] === 'buyouts' ? false : !directions[0]?.parcelIdentByOrderNumber,
    sendAsBuyout: pathname.split('/')[1] === 'buyouts',
    track_code: track ? track?.number : '',
    delivery_type: 1,
    package_type: 0,
    insurance: false,
    prods: goodsFields ? goodsFields : defFields,
    invoice_file: null,
    invoiceLinks: track?.invoice ? [track?.invoice] : [''],
    orderInspection: false,
    inspectionDescription: '',
    receiverNeeded: true,
    receiver: track ? {label: '', value: track.receiver?.id} : receiversListData[0],
    require_invoice: 0,
    description: '',
  }

  // useStates
  const [initValues] = useState<AddTrackForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  const [trackCode, setTrackCode] = useState(track ? track.number : '')

  const [trackCodeStatus, setTrackCodeStatus] = useState({success: false, msg: '', id: ''})

  useEffect(() => {
    inits.receiver = receiversListData[0]
  }, [receiversListData])

  //mutations
  const createTrackMutation = useMutation(addTrack, {
    onMutate: () => {
      setIsLoading(true)
      // setCreateResponse('loading')
    },
    onSuccess: (data) => {
      // setCreateResponse('success')
      // setItemIdForUpdate(undefined)
      setInvoiceArray([])
      setTrackCode('')
      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      // setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const createOrderMutation = useMutation(addOrder, {
    onMutate: () => {
      setIsLoading(true)
      // setCreateResponse('loading')
    },
    onSuccess: (data) => {
      // setCreateResponse('success')
      // setItemIdForUpdate(undefined)
      setInvoiceArray([])
      setTrackCode('')
      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = async (values: AddTrackForm, formikHelpers: FormikHelpers<AddTrackForm>) => {
    const {track_code, prods, user, direction, invoiceLinks, store} = values
    const products = await reformTrackFormProductsToApi(prods)
    const data: Partial<ICreateNewTrack> & Partial<ICreateNewOrder> = {
      direction: direction.value ? {id: direction.value} : null,
      user: {id: user?.value},
      products,
      invoice: null,
      invoiceLink: invoiceLinks && invoiceLinks[0] ? invoiceLinks[0] : null,
    }

    if (!values.sendAsBuyout) {
      data.number = track_code
    } else {
      data.type = 'buyout'
      data.websiteShop = {id: store?.id}
    }

    if (values.sendAsBuyout === false && values.sendAsTrack === true) {
      createTrackMutation.mutate(data)
    } else {
      createOrderMutation.mutate(data)
    }
    const lastDir = values.direction

    formikHelpers.resetForm()
    formikHelpers.setFieldValue('track_code', '')
    formikHelpers.setFieldValue('direction', lastDir)
  }

  useEffect(() => {
    document.body.style.overflow = ''
    if (filterUsers) filterUsers('')
  }, [])

  return (
    <Formik
      validationSchema={createTrackOnePageSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form' noValidate id='kt_modal_create_track_form'>
            <ScrollToFieldError />
            <OnePageModalHeader
              trackNumber={trackCode.toUpperCase() || ''}
              trackCodeStatus={trackCodeStatus}
              isLoading={isLoading}
              isValid={isValid}
              asTrack={values.sendAsTrack}
              listView={listView}
            />
            {/* {JSON.stringify(errors)} */}
            <div
              className='scroll-y modal-body p-0'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div
                className={`p-3 ${
                  values?.direction?.minBuyoutPrice &&
                  values.prods.reduce((acc, i) => acc + +i.total, 0) <
                    values?.direction?.minBuyoutPrice
                    ? 'pt-8'
                    : ''
                } text-primary`}
              >
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <DirectionsSelect />
                  </div>
                </div>
                {/* {JSON.stringify(values.sendAsBuyout)} */}

                {values.sendAsBuyout === false && (
                  <div className='w-100'>
                    <div className='fv-row mb-5'>
                      <TrackCodeField
                        setFieldValue={setFieldValue}
                        title={
                          values.sendAsTrack === false
                            ? 'ORDERS.ADD_MODAL.FORM.ORDER_CODE_TITLE'
                            : 'TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE'
                        }
                        value={values.track_code}
                        trackCodeStatus={trackCodeStatus}
                        setTrackCodeStatus={setTrackCodeStatus}
                        trackCode={trackCode}
                        disabled={!!listView.itemIdForUpdate}
                        setTrackCode={setTrackCode}
                        twoFactorCheck={false}
                      />
                    </div>
                  </div>
                )}
                <div className='w-100'>
                  {setUsersLoading &&
                    setUsersResults &&
                    usersResults &&
                    (usersLoading === true || usersLoading === false) &&
                    filterUsers && (
                      <ClientSelect
                        setUsersLoading={setUsersLoading}
                        setUsersResults={setUsersResults}
                        options={usersResults}
                        isLoading={usersLoading}
                        currentValue={values.user}
                        onInputChange={filterUsers}
                        widthClassName='w-100 text-dark'
                      />
                    )}
                </div>

                {/* {JSON.stringify(errors)}
                {JSON.stringify(values)} */}
              </div>{' '}
              <div className='separator bg-primary h-3px mb-3'></div>
              <div className='p-3 pb-6'>
                <div className='w-100'>
                  <TrackGoods
                    prods={values.prods}
                    setFieldValue={setFieldValue}
                    values={values}
                    listView={listView}
                  />
                </div>
              </div>
              <div className='separator bg-primary h-3px my-3'></div>
              {/* <div className='p-3 pt-6'>
                <TrackParcelType
                  values={values}
                  disabledCommercialSettings={disabledCommercialSettings}
                />
              </div>
              <div className='separator bg-primary h-3px my-3'></div> */}
              {values.sendAsBuyout === false && (
                <>
                  {' '}
                  <div className='p-3'>
                    <TrackInvoicesTab
                      invoiceArray={invoiceArray}
                      recentInvoicesData={recentInvoicesData}
                      setInvoiceArray={setInvoiceArray}
                      setIsLoading={setIsLoading}
                      values={values}
                    />
                  </div>
                  <div className='separator bg-primary h-3px mb-3'></div>
                </>
              )}
              {/* <div className='p-3'>
                <TrackInspectionTab values={values} />{' '}
              </div>
              <div className='separator bg-primary h-3px my-3'></div> */}
              {receiversListData && (
                <div className='p-3'>
                  <div className='fv-row mb-3'>
                    <InputTemplate
                      type='textarea'
                      inputName='description'
                      title={intl.formatMessage({
                        id: 'TRACKS.ADD_MODAL.FORM.COMMENT_TITLE',
                      })}
                      titleFontSize='fs-6'
                      required={false}
                      containerMarginBottom={false}
                      disabled={!!listView.itemIdForUpdate}
                    />
                  </div>{' '}
                  <TrackReceiverInput values={values} receiversListData={receiversListData} />
                </div>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateTrackOnePageForm}
