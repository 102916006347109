import {Column} from 'react-table'
import {CountryCustomHeader} from './CountryCustomHeader'
import {ICountry} from '../../core/_models'
import {CountryIdCell} from './cells/CountryIdCell'
import {CountryActionsCell} from './cells/CountryActionsCell'
import {CountryNameCell} from './cells/CountryNameCell'
import {CountryTypeCell} from './cells/CountryTypeCell'
import {CountryIconCell} from "./cells/CountryIconCell";

const countriesColumns: ReadonlyArray<Column<ICountry>> = [
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={''}
      />
    ),
    id: 'flag',
    Cell: ({...props}) => <CountryIconCell country={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'COUNTRIES_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'country',
    Cell: ({...props}) => <CountryNameCell country={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'COUNTRIES_COLUMNS_ID'}
        className='min-w-125px text-center'
      />
    ),
    id: 'id',
    Cell: ({...props}) => <CountryIdCell country={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'COLUMNS_TYPE'}
        className='min-w-120px text-center'
      />
    ),
    id: 'type',
    Cell: ({...props}) => <CountryTypeCell country={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <CountryActionsCell country={props.data[props.row.index]} />,
  },
]

export {countriesColumns}
