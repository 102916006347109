import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES, selectDebounce, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../InputTemplate'
import {getCountries} from '../../../../pages/countries/countries-list/core/_requests'
import {ICountryConfigForm} from '../../../../pages/warehouses/core/_models'
import flagsData from "../../../../../data/general/flags.json";

const CountriesSelect = ({
    queryFilter = '',
    inputName = 'countryConfig',
    withTitle = true,
    isMulti = false,
    isClearable = false,
}) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<{countryConfig: ICountryConfigForm}>()
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterCountries = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      getCountries(queryFilter)
        .then((res) => {
          const reformed = res?.data?.map((item) => {
            return {
              ...item,
              label: item.name||countries[item.country],
              value: item.id,
              image: flagsData.find((i) => i.country === item.country)?.flag || '/media'
            }
          })
          if (reformed) {
            setResults(reformed)
          }
          if (callback) {
            callback(reformed)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [getCountries, countries]
  )

  useEffect(() => {
    filterCountries()
  }, [filterCountries])

  const selectProps = {
    ...(isMulti && { isMultiple: true }),
    ...(isClearable && { isClearable: true }),
  };
  const optionFormat = (v: IBasicSelect & { image: string }) => (
    <div className='tagify__dropdown__item d-flex align-items-center h-30px' tabIndex={0}>
      <img
        src={toAbsoluteUrl(v.image)}
        width={'20px'}
        alt=''
        className={`svg-icon-3 FlagsDirectionBar__flag ms-2`}
      />
      <div className='d-flex flex-column text-gray-600'>{v.label}</div>
    </div>
  )

  return (
    <InputTemplate
      inputName={inputName}
      required={true}
      containerMarginBottom={false}
      type='select'
      // value={values.countryConfig || {value: '', label: ''}}
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.CountriesSelect.LABEL'}) : ''}
      titleFontSize='fs-5 text-dark'
      reactSelectprops={{
        ...selectProps,
        optionFormat,
        // @ts-ignore
        currentValue: values[inputName] || (selectProps.isMulti?[]:null),
        options: results,
        selectName: inputName,
        isLoading: resultsLoading,
        onInputChange: filterCountries,
        setFieldValue: setFieldValue,
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'WAREHOUSES.ADD_MODAL.FORM.COUNTRIES_PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'WAREHOUSES.ADD_MODAL.FORM.NO_COUNTRIES_MESSAGE',
        }),
      }}
    />
  )
}

export default CountriesSelect
