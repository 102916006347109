import {Form, Formik} from 'formik'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {IWebsite, ITranslationObject} from '../../../../websites/core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {IWebsitePage} from '../../../website-pages-list/core/_models'
import {updateKey} from '../../../../websites/elements/TranslationBoard/core/_requests'
import {editWebsitePage} from "../../../website-pages-list/core/_requests";

interface Props {
  page: IWebsitePage
  invalidateData: () => void
  breadcrumb: ITranslationObject[]
  slug: ITranslationObject[]
  website: IWebsite
}
const PageOverview = ({page, invalidateData, breadcrumb, slug, website}: Props) => {
  const {locale = 'uk'} = useParams()

  const initialValues = {
    breadcrumbs: breadcrumb[0].translations.find((i) => i.locale === locale)?.value || '',
    slug: page.slug
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: any) => {
    setLoading(true)

    try {
      let promises = breadcrumb.map(async (key) => {
        const metaKeyNameForForm = key?.keyName
          ?.split('.')
          [key?.keyName?.split('.').length - 1].split('-')[0]
        const translationsToSend = {
          ...key,
          translations: [
            ...key.translations.filter((item) => item.locale !== locale),
            {locale: locale, value: values[metaKeyNameForForm]},
          ],
        }
        await updateKey(translationsToSend, key.id)
      })
      promises.concat(slug.map(async (key) => {
        const translationsToSend = {
          ...key,
          translations: slug[0].translations.map((item) => ({locale: item.locale, value: values.slug}))
        }
        await updateKey(translationsToSend, key.id)
      }))
      promises.push(
        editWebsitePage(page.id, {slug: values.slug})
      )

      await Promise.all(promises)

      invalidateData()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-2'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='website-page'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Хлібні крихти</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='breadcrumbs' required={true} type='text' />
                </div>
              </div>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Посилання</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='slug' required={true} type='text' />
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PageOverview
