/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {QUERIES} from '../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {Formik, Form, ErrorMessage, FormikHelpers} from 'formik'
import {ICreateNewCourier, ICreateNewCourierForm} from '../core/_models'
import {couriersApi} from '../core/_requests'
import ScrollToFieldError from './ScrollToFieldError'
import {CreateCourierModalHeader} from './CreateCourierModalHeader'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {FormattedMessage, useIntl} from 'react-intl'
import {createCourierSchema} from '../core/yup'
import CountriesSelect from '../../../modules/custom/form-elements/selects/CountriesSelect'
import {CountriesQueryResponse} from '../../countries/countries-list/core/_models'
import {handleFileUpload} from '../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FileInput} from '../../../modules/custom/form-elements/file-inputs/FileInput'
import {useQueryResponse} from '../core/QueryResponseProvider'
import DeliveryMethods from '../modules/DeliveryMethods'
import setErrorsForFormFromErrorsObject from '../../../../_metronic/helpers/custom/funcs/setErrorsForFormFromErrorsObject'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

export const delMethods = [
  {name: 'Адреса', type: 'address'},
  {name: 'Відділення', type: 'warehouse'},
  {name: 'Поштомат', type: 'postomat'},
]

const CreateCourierForm = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()
  const [isLoading, setIsLoading] = useState(false)

  const [createResponse, setCreateResponse] = useState('')
  // const existingServices: string[] = queryClient.getQueryData([QUERIES.COURIER_TYPES]) || []
  const existingServices = ['nova_poshta']
  const countriesData: CountriesQueryResponse =
    queryClient.getQueryData(QUERIES.COUNTRIES_LIST) || {}
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const [imageIsLoading, setImageIsLoading] = useState(false)

  const [currLogo, setCurrLogo] = useState({logoUrl: '', logo: ''})

  const inits: ICreateNewCourierForm = {
    ownership: 0,
    name: '',
    type: 'custom',
    deliveryMethods: ['address'],
    countryConfig: {
      ...countriesData?.data?.[0],
      value: countriesData?.data?.[0]?.id || '',
      label: countriesData?.data?.[0]?.country ? countries[countriesData?.data?.[0]?.country] : '',
    },
    logo: '',
  }

  const [formik, setFormik] = useState<{
    values: ICreateNewCourierForm
    formikHelpers: FormikHelpers<ICreateNewCourierForm>
  } | null>(null)

  const mutation = useMutation(couriersApi.addCourier, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.DIRECTIONS_WITH_IDS_LIST)
      setCreateResponse('success')
      formik?.formikHelpers.resetForm()
      refetch()
    },
    onMutate: () => {
      setCreateResponse('loading')
    },
    onError: (error: any) => {
      setCreateResponse('error')
      console.log(error)
      if (formik && error.response?.data?.errors) {
        setErrorsForFormFromErrorsObject({
          error,
          values: formik?.values,
          formikHelpers: formik?.formikHelpers,
          intl,
        })
      }
    },
  })

  const handleSubmit = (
    values: ICreateNewCourierForm,
    formikHelpers: FormikHelpers<ICreateNewCourierForm>
  ) => {
    try {
      const {type, deliveryMethods, countryConfig, name} = values
      const dataToSend: Partial<ICreateNewCourier> = {
        type,
        enabled: true,
        deliveryMethods: deliveryMethods.map((i) => {
          return {name: delMethods.find((it) => it.type === i)?.name || '', type: i}
        }),
        name: type === 'custom' ? name : 'Нова Пошта',
        countryConfig: {id: countryConfig?.value},
      }
      if (type === 'custom') {
        dataToSend.logo = currLogo.logo || null
      }

      setFormik({values, formikHelpers})

      mutation.mutate(dataToSend)
    } catch (error: any) {
      console.log(error)
    }
  }

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setIsLoading(true)
    setImageIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'logo',
      'logo',
      couriersApi.uploadFile,
      false,
      'logoUrl'
    )

    if (linkResponse) {
      setCurrLogo(linkResponse)
    } else {
      // set error
      console.log('err set logo')
    }
    setImageIsLoading(false)
    setIsLoading(false)
  }

  return (
    <>
      {countries && countriesData ? (
        <Formik
          validationSchema={createCourierSchema}
          initialValues={inits}
          onSubmit={handleSubmit}
        >
          {({values, errors, setFieldValue, isValid}) => (
            <Form className='form' noValidate id='kt_modal_create_direction_form'>
              <ScrollToFieldError />
              <CreateCourierModalHeader isLoading={isLoading} isValid={isValid} />
              {/* {JSON.stringify(errors)} */}
              {/* {JSON.stringify(values.countryConfig)} */}
              <div
                className='scroll-y modal-body p-0'
                style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
              >
                <div className='p-3'>
                  <div className='w-100'>
                    <CountriesSelect queryFilter='filter[receive]=true' />
                  </div>
                </div>
                <div className='p-3'>
                  {' '}
                  <div className='w-100'>
                    {' '}
                    <p className='required fs-5 fw-bold mb-7'>
                      <FormattedMessage id='COURIERS.ADD_MODAL.FORM.OWNERSHIP_L' />
                    </p>
                    <InputTemplate
                      inputName='ownership'
                      required={false}
                      type='radio'
                      value={0}
                      title={intl.formatMessage({
                        id: 'COURIERS.ADD_MODAL.FORM.OWNERSHIP.COMPANY_L',
                      })}
                      checkboxProps={{
                        checked: +values.ownership === 0,
                        disabled: false,
                        setFieldValue: setFieldValue,
                        value: 0,
                        inputName: 'ownership',
                        type: 'radio',
                        handleChange: () => setFieldValue('type', 'custom'),
                      }}
                    />
                    <InputTemplate
                      inputName='ownership'
                      required={false}
                      type='radio'
                      value={1}
                      containerMarginBottom={false}
                      title={intl.formatMessage({
                        id: 'COURIERS.ADD_MODAL.FORM.OWNERSHIP.EXISTING_L',
                      })}
                      checkboxProps={{
                        checked: +values.ownership === 1,
                        disabled: false,
                        setFieldValue: setFieldValue,
                        value: 1,
                        inputName: 'ownership',
                        type: 'radio',
                        handleChange: () => setFieldValue('type', existingServices[0]),
                      }}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='ownership' />
                    </div>
                  </div>
                </div>
                <div className='separator bg-primary h-3px mb-3'></div>
                <div className='p-3'>
                  <div className='w-100'>
                    {+values.ownership === 0 && (
                      <>
                        {' '}
                        <p className='fs-5 fw-bold mb-7'>
                          <FormattedMessage id='COURIERS.ADD_MODAL.FORM.OWNERSHIP.COMPANY_DETAILS' />
                        </p>
                        <InputTemplate
                          inputName='name'
                          type='text'
                          title={intl.formatMessage({id: 'COURIERS.FORM.NAME'})}
                          required={true}
                        />
                        <FileInput
                          title={intl.formatMessage({
                            id: 'GENERAL.UPLOAD_IMAGE_LABEL',
                          })}
                          inputName='logo'
                          inputId={`courier-logo-input`}
                          uploadedFile={{
                            base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                            link: currLogo?.logoUrl || '',
                          }}
                          setFieldValue={setFieldValue}
                          uploadFunction={saveImage}
                          simpleDeleteFunction={() => setCurrLogo({logoUrl: '', logo: ''})}
                          loading={isLoading}
                        />
                      </>
                    )}
                    {+values.ownership === 1 && (
                      <>
                        <p className='required fs-5 fw-bold mb-7'>
                          <FormattedMessage id='COURIERS.ADD_MODAL.FORM.OWNERSHIP.EXISTING_OPTIONS' />
                        </p>
                        {existingServices.map((i) => (
                          <InputTemplate
                            key={i}
                            inputName='type'
                            required={false}
                            type='radio'
                            value={i}
                            title={intl.formatMessage({
                              id: `COURIERS.EXISTING_SERVICES.${i.toUpperCase()}`,
                            })}
                            checkboxProps={{
                              checked: values.type === i,
                              disabled: false,
                              setFieldValue: setFieldValue,
                              value: i,
                              inputName: 'type',
                              type: 'radio',
                            }}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className='separator bg-primary h-3px mb-3'></div>
                <div className='p-3'>
                  <div className='w-100'>
                    <p className='required fs-5 fw-bold mb-7'>
                      <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
                    </p>
                    <DeliveryMethods delMethods={delMethods} />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </>
  )
}

export {CreateCourierForm}
