import {Formik, Form, FieldArray} from 'formik'
import {useEffect, useState} from 'react'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {IEditStore, TStoreAdvantage} from '../../core/_models'
import {editStore} from '../../core/_requests'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../websites/core/_models'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'
import {CKEInput} from "../../../../modules/custom/form-elements/CKEInput";

interface Props {
  store: IEditStore
  invalidateStore: () => void
}

const initAdvantages = [{name: '', description: ''}, {name: '', description: ''}, {name: '', description: ''}];
const initSteps = [''];
export function StoreExtraFields({store, invalidateStore}: Props) {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === store.website.id)

  const {locale} = useParams()
  const currLocale = locale || website?.mainLocale || 'uk'

  const [initialValues, setInitVals] = useState({advantages: initAdvantages, orderSteps: initSteps})
  const [loading, setLoading] = useState(false)

  useEffect(function () {
    const advantages =
      store?.translations &&
      store?.translations[currLocale] &&
      store?.translations[currLocale].advantages?.length ? store.translations[currLocale].advantages : initAdvantages
    const steps =
      store?.translations &&
      store?.translations[currLocale] &&
      store?.translations[currLocale].orderSteps
        ?.map((item) => item.trim())
        .filter(function (el) {
          return el !== ''
        })
    // @ts-ignore
    setInitVals({advantages: advantages, orderSteps: steps?.length?steps:initSteps})
  }, [currLocale, store.translations])

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {advantages: TStoreAdvantage[], orderSteps: string[]}) => {
    setLoading(true)

    try {
      let translations = {
        // ...store.translations,
        [currLocale]: {
          advantages: values.advantages,
          orderSteps: values.orderSteps,
        },
      }
      const dataToSend = {
        translations,
      }

      await editStore(dataToSend, store.id)

      invalidateStore()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <div className='card-header pt-5'>
        <h3>Дод. поля</h3>
      </div>
      {store?.translations && initialValues ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-text-store'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {store?.translations && initialValues ? (
                  <div>
                    <div className='row'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Переваги 1
                      </label>

                      <div className='col-lg-8'>
                        <InputTemplate inputName={`advantages.0.name`} required={false} type='text'/>
                        <InputTemplate
                          inputName={`advantages.0.description`}
                          type='textarea'
                          backgroundClass='min-h-150px'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Переваги 2
                      </label>

                      <div className='col-lg-8'>
                        <InputTemplate inputName={`advantages.1.name`} required={false} type='text'/>
                        <InputTemplate
                          inputName={`advantages.1.description`}
                          type='textarea'
                          backgroundClass='min-h-150px'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Переваги 3
                      </label>

                      <div className='col-lg-8'>
                        <InputTemplate inputName={`advantages.2.name`} required={false} type='text'/>
                        <InputTemplate
                          inputName={`advantages.2.description`}
                          type='textarea'
                          backgroundClass='min-h-150px'
                        />
                      </div>
                    </div>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                      Кроки замовлення
                    </label>
                    <FieldArray
                      name='orderSteps'
                      render={(arrayHelpers) => (
                        <div className='d-flex flex-column flex-col-gutter'>
                          {values.orderSteps.map((p, index) => (
                            <div
                              key={index}
                              className={`row ${
                                index === values.orderSteps.length - 1
                                  ? 'd-flex align-items-center'
                                  : ''
                              }`}
                            >
                              {' '}
                              <div
                                className={`col-lg-3 col-form-label fw-bold fs-6 d-flex flex-column flex-col-gutter align-items-center`}
                              >
                                {values.orderSteps.length > 1 && (
                                  <div>
                                    <button
                                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary m-0'
                                      type='button'
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen040.svg'
                                        className='svg-icon-1x svg-icon-primary d-block m-0'
                                      />
                                    </button>
                                  </div>
                                )}
                                {index === values.orderSteps.length - 1 && (
                                  <div>
                                    <button
                                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
                                      type='button'
                                      onClick={() => {
                                        arrayHelpers.push('')
                                      }}
                                    >
                                      {/* <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' /> */}
                                      Додати абзац
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen041.svg'
                                        className='svg-icon-1x svg-icon-primary d-block m-0 mx-2'
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-9'>
                                <CKEInput inputName={`orderSteps.${index}`} value={values.orderSteps[index]} extended={false} key={currLocale} />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                ): ''}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false}/>
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader/>
      )}
    </div>
  )
}
