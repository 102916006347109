/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ICountry} from '../../countries-list/core/_models'
import {CountryHeaderName} from './CountryHeaderName'
import {CountryHeaderInfo} from './CountryHeaderInfo'
import {CountryHeaderNav} from './CountryHeaderNav'
import {useParams} from "react-router-dom";
import CountryHeaderLangSwitcher from "./CountryHeaderLangSwitcher";

interface Props {
  country: ICountry
}

const CountryHeader = ({country}: Props) => {
  const params = useParams()
  const path = Object.values(params)[0]?.split('/')[0]
  const showLangSwitcher = path !== 'address' && path !== 'images'
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/icons/duotune/maps/map008.svg')} alt='Location' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <CountryHeaderName country={country} />{' '}
            </div>
          </div>
          <CountryHeaderInfo country={country} />
          {showLangSwitcher && <CountryHeaderLangSwitcher country={country} />}
        </div>

        <CountryHeaderNav country={country} />
      </div>
    </div>
  )
}

export {CountryHeader}
