import {useState} from 'react'
import {QUERIES} from '../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {CreateError} from '../../websites/responseHandlers/CreateError'
import {CreateLoading} from '../../websites/responseHandlers/CreateLoading'
import {Formik, Form, FormikValues} from 'formik'
import {IPollForm, IPollTranslation} from '../core/_models'
import ScrollToFieldError from './ScrollToFieldError'
import {CreatePollModalHeader} from './CreatePollModalHeader'
import {useIntl} from 'react-intl'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {createPollSchema} from '../core/yup'
import {IBasicSelect} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IWebsite} from '../../websites/core/_models'
import WebsitesSelect from '../../../modules/custom/form-elements/selects/WebsitesSelect'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import QuestionsAnswers from '../modules/QuestionsAnswers'
import {pollsApi} from '../core/_requests'
import {transformFormQuestionsForCreateApi} from '../core/funcs/transformQuestions'
interface Props {
  websites: (IBasicSelect & Partial<IWebsite>)[]
}

const CreatePollForm = ({websites}: Props) => {
  const intl = useIntl()
  // queries
  const queryClient = useQueryClient()
  const pollTypes: string[] | undefined = queryClient.getQueryData(QUERIES.POLL_TYPES_LIST)
  // context
  const {refetch} = useQueryResponse()

  // states
  const [isLoading, setIsLoading] = useState(false)

  const [createResponse, setCreateResponse] = useState('')

  //  the rest
  const inits: Partial<IPollForm> = {
    name: '',
    website: websites[0] || null,
    questions: [
      {
        question: '',
        allowOwnAnswer: false,
        answers: [{answer: ''}],
        allowDelete: true,
      },
    ],
    type: pollTypes ? pollTypes[0] : '',
  }

  const mutation = useMutation(pollsApi.addPoll, {
    onSuccess: () => {
      refetch()
    },
  })

  const handleSubmit = (values: Partial<IPollForm>, actions: FormikValues) => {

    const {website, name, type} = values

    const pollData = {
      translations: website?.enabledLocales?.reduce<IPollTranslation>((acc, locale) => {
        acc[locale] = {name: name || ''}
        return acc
      }, {}),
      website: {
        id: website?.value,
      },
      enabled: true,
      type: type || 'normal',
      questions:
        values?.questions && website
          ? transformFormQuestionsForCreateApi(values?.questions, website)
          : [],
    }

    mutation.mutate(pollData)

    actions.resetForm()
  }

  return (
    <Formik
      validationSchema={createPollSchema}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, errors, setFieldValue, isValid}) => (
        <Form className='form' noValidate id='kt_modal_create_direction_form'>
          <ScrollToFieldError />
          <CreatePollModalHeader isLoading={isLoading} isValid={isValid} />
          {/* {JSON.stringify(errors)} */}
          {/* {JSON.stringify(values)} */}
          <div
            className='scroll-y modal-body p-0'
            style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
          >
            <div className='p-3'>
              <InputTemplate
                inputName='name'
                type='text'
                title={intl.formatMessage({id: 'POLLS.ADD_MODAL.FORM.NAME'})}
                titleFontSize='fs-5 text-dark'
              />

              <WebsitesSelect />
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <QuestionsAnswers values={values} />
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <p className='fw-bold fs-5 required'>Умова старту</p>
              {pollTypes?.map((type) => (
                <InputTemplate
                  key={type}
                  inputName='type'
                  type='radio'
                  value={type}
                  title={intl.formatMessage({
                    id: `POLLS.ADD_MODAL.FORM.${type.toUpperCase()}`,
                  })}
                  checkboxProps={{
                    checked: values.type === type ? true : false,
                    disabled: false,
                    setFieldValue: setFieldValue,
                    value: type,
                    inputName: 'type',
                    type: 'radio',
                  }}
                />
              ))}
            </div>{' '}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {CreatePollForm}
