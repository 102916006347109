import {Form, Formik, FormikValues} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {FileInput} from '../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {IEditStore, IEditStoreForm} from '../../../core/_models'
import {editStore, uploadImage} from '../../../core/_requests'
import {InputTemplate} from "../../../../../modules/custom/form-elements/InputTemplate";
import {useParams} from "react-router-dom";

interface Props {
  store: IEditStore
  invalidateStore: () => void
}

export type TImage = {
  image?: string
  imageUrl?: string
  loading: boolean
}

type TLogo = {logo?: string; logoUrl?: string; loading: boolean}

const StoreImages = ({store, invalidateStore}: Props) => {
  const {locale = 'uk'} = useParams()
  const intl = useIntl()

  const initTranslations =
    store?.translations && store?.translations[locale]
      ? {...store?.translations[locale]}
      : {
        imageAlt: '',
        logoAlt: '',
      }
  const initialValues: Partial<IEditStoreForm> = {
    image: store.image || '',
    logo: store.logo || '',
    ...initTranslations,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const [imageObj, setImageObj] = useState<TImage | null>({
    image: store.image || '',
    imageUrl: store.imageUrl || '',
    loading: false,
  })
  const [logoObj, setLogoObj] = useState<TLogo | null>({
    logo: store.logo || '',
    logoUrl: store.logoUrl || '',
    loading: false,
  })

  const handleSubmit = async (values: Partial<IEditStoreForm>, actions: FormikValues) => {
    const {imageAlt, logoAlt} = values
    setLoading(true)

    try {
      let translations = {
        ...store?.translations,
        [locale]: {...initTranslations, ...store?.translations?.locale, imageAlt, logoAlt},
      }
      const dataToSend: Partial<IEditStore> = {
        image: imageObj?.image || null,
        logo: logoObj?.logo || null,
        translations
      }

      await editStore(dataToSend, store.id)

      invalidateStore()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  const loadingSetter = (field: string, bool: boolean) => {
    if (field === 'image') {
      setImageObj((prev: any) =>
        prev
          ? {
              ...prev,
              loading: bool,
            }
          : {loading: bool}
      )
    } else if (field === 'logo') {
      setLogoObj((prev) =>
        prev
          ? {
              ...prev,
              loading: bool,
            }
          : {loading: bool}
      )
    }
  }
  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    field: string
  ) => {
    try {
      loadingSetter(field, true)

      const linkResponse = await handleFileUpload(
        e,
        setFieldValue,
        field,
        field,
        uploadImage,
        false,
        `${field}Url`
      )

      if (field === 'image') {
        setImageObj(linkResponse)
      }
      if (field === 'logo') {
        setLogoObj(linkResponse)
      }

      invalidateStore()
    } catch (error) {
      console.log(error)
    } finally {
      loadingSetter(field, false)
    }
  }

  const deleteImage = async (setFieldValue: ISetFieldValue, field: string) => {
    setFieldValue(field, '')

    if (field === 'image') {
      setImageObj(null)
    }
    if (field === 'logo') {
      setLogoObj(null)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-images-store'>
            {/* {JSON.stringify(values)} */}

            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              {Object.entries({
                image: imageObj,
                logo: logoObj,
              }).map(([k, v]) => (
                <div key={k}>
                  <FileInput
                    title={intl.formatMessage({
                      id: `STORES.UPLOAD_${k.toUpperCase()}_LABEL`,
                    })}
                    inputName={k}
                    inputId={`${store.id}-store-${k}-file-input`}
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                      link: v?.[`${k}Url` as keyof typeof imageObj] || '',
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteImage}
                    loading={v?.loading || false}
                  />
                  <div className='row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({
                      id: `STORES.UPLOAD_${k.toUpperCase()}_LABEL`,
                    })} Alt</label>

                    <div className='col-lg-8'>
                      <InputTemplate inputName={`${k}Alt`} required={false} type='text' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false}/>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StoreImages
