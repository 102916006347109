/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ITranslationObject} from '../../../../../core/_models'
import {useIntl} from 'react-intl'

type Props = {
  translation: ITranslationObject
}

const TranslationActionsCell: FC<Props> = ({translation}) => {
  const intl = useIntl()
  const {id} = useParams()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const availableLangs = translation ? translation.translations.map((i) => i.locale) : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        {/* <button
          type='button'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => openEditModal()}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button> */}
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
        >
          <Link
            to={`/websites/translation/${id}/edit/overview/${availableLangs[0]}/${translation.id}`}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>
        </OverlayTrigger>
      </div>
    </>
  )
}

export {TranslationActionsCell}
