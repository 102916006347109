import {Formik, Form} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../../_metronic/helpers'
import {updateKey} from '../../core/_requests'
import {ITranslationObject} from '../../../../core/_models'
import PartialPageLoader from '../../../../../../../_metronic/layout/core/PartialPageLoader'
import {InputTemplate} from "../../../../../../modules/custom/form-elements/InputTemplate";

interface Props {
  translation: ITranslationObject
  invalidateTranslation: () => void
}

export function CommentTab({translation, invalidateTranslation}: Props) {
  const initialValues = {
    note: translation?.note ? translation?.note : '',
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {note: string}) => {
    setLoading(true)

    try {
      const translationsToSend = {
        ...translation,
        note: values.note,
      }

      await updateKey(translationsToSend, translation.id)

      invalidateTranslation()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <div className='card-header pt-5'>
        <p className='fw-bolder'> {translation.keyName}</p>
      </div>
      {translation?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-client-tr'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Коментар</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='note' required={false} type='text'/>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false}/>
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader/>
      )}
    </div>
  )
}
