import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CreateShipmentModalHeader} from './CreateShipmentModalHeader'
import {QUERIES} from '../../../../_metronic/helpers'
import WarehousesSelect from '../../../modules/custom/form-elements/selects/WarehousesSelect'
import {IWarehouse} from '../../warehouses/core/_models'
import {createShipmentSchema} from '../core/yup/shipment'
import {useIntl} from 'react-intl'
import {ICreateShipment, ICreateShipmentForm} from '../core/_models/_models'
import {createShipment} from '../core/_requests'
import {getTracksForNewShipment} from '../../tracks/core/_requests'

const CreateShipmentForm = () => {
  const intl = useIntl()
  // service
  const {refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  // form fields data

  const warehouses: IWarehouse[] = queryClient.getQueryData(QUERIES.WAREHOUSES_LIST) || []

  const inits = {
    location: warehouses[0] || {value: '', label: ''},
    tracksAvailable: [],
    trackingNumbers: [],
  }

  // useStates
  // @ts-ignore
  const [initValues] = useState<ICreateShipmentForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  //mutation
  const acceptMutation = useMutation(createShipment, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      // setItemIdForUpdate(undefined)
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = async (values: ICreateShipmentForm, formikHelpers: FormikHelpers<any>) => {
    const {location} = values

    const tracksAvailable = await getTracksForNewShipment(location.value)
    const data: Partial<ICreateShipment> = {
      location: {id: location.value},
      trackingNumbers: tracksAvailable?.map((item) => {
        return {id: item.id}
      }),
    }

    acceptMutation.mutate(data)
    const lastLoc = values.location
    formikHelpers.resetForm()
    formikHelpers.setFieldValue('location', lastLoc)
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={createShipmentSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_track_form'>
            <CreateShipmentModalHeader isLoading={isLoading} />
            {/* {JSON.stringify(errors)}  */}
            {/* {JSON.stringify(values)} */}
            <div
              className='scroll-y modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <WarehousesSelect />
                  </div>
                </div>
              </div>{' '}
              {/* {values.location.value && (
                <div className='p-3 pb-6'>
                  <div className='w-100'>
                    <div className='fv-row mb-5'>
                      <TrackingNumbers values={values} />
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateShipmentForm}
