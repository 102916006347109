import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

interface Props {
  isLoading: boolean
  isValid: boolean
}

const CreateCourierModalHeader = ({isLoading, isValid}: Props) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div
      className='modal-header px-6 py-2 position-fixed top-0 w-100 w-md-500px d-flex justify-content-between'
      style={{zIndex: 1, overflowX: 'hidden'}}
    >
      <h2 className='fw-bold fs-3 p-3'>
        <FormattedMessage id={'COURIERS.ADD_MODAL.HEADER.CREATE'} />
        <p className='fw-normal fs-6 m-0 mt-1'></p>
      </h2>

      <div className='d-flex flex-gutter'>
        <button
          type='submit'
          disabled={!!itemIdForUpdate || isLoading === true ? true : false}
          className='btn btn-sm btn-primary me-3 minify-button fs-7 fs-md-5'
        >
          <span className='indicator-label'>
            {isLoading === true ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <FormattedMessage
                id={itemIdForUpdate ? 'GENERAL.BUTTONS.SAVE' : 'GENERAL.BUTTONS.CREATE'}
              />
            )}
          </span>
        </button>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
          onClick={() => {
            setItemIdForUpdate(undefined)
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>
    </div>
  )
}

export {CreateCourierModalHeader}
