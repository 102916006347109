import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {TranslationHeader} from './header/TranslationHeader'
import {getTranslationKey} from '../core/_requests'
import {TranslationTab} from './content/TranslationTab'
import {ITranslationObject} from '../../../core/_models'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {CommentTab} from "./content/CommentTab";

interface Props {
  id: string | number
}

const WebsiteTranslationPage = ({id}: Props) => {
  const location = useLocation()
  const key = +location.pathname.split('/')[location.pathname.split('/').length - 1]
  const {data: translationById} = useQuery(`website-translation-${key}`, () =>
    getTranslationKey(key)
  )

  const [currTranslation, setCurrTranslation] = useState<ITranslationObject | undefined>(undefined)

  const queryClient = useQueryClient()

  const invalidateTranslation = () => {
    queryClient.invalidateQueries(`website-translation-${key}`)
  }

  useEffect(() => {
    if (translationById) {
      setCurrTranslation(translationById)
    }
  }, [translationById])

  return (
    <>
      {currTranslation && translationById ? (
        <Routes>
          <Route
            element={
              <>
                <TranslationHeader translation={currTranslation} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:key`}
              element={
                <>
                  <TranslationTab
                    translation={currTranslation}
                    invalidateTranslation={invalidateTranslation}
                  />
                </>
              }
            />
            <Route
              path={`comment/:locale/:key`}
              element={
                <>
                  <CommentTab
                    translation={currTranslation}
                    invalidateTranslation={invalidateTranslation}
                  />
                </>
              }
            />

            <Route index element={<Navigate to={`/websites/translation/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default WebsiteTranslationPage
