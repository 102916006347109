import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useQueries, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {WebsiteHeader} from './header/WebsiteHeader'
import {generalApi} from '../../../../_metronic/helpers/custom/api/generalApi'
import {websitesApi} from '../core/_requests'
import {IWebsite} from '../core/_models'
import {TermsOfServiceTab} from './tabs/tos/TermsOfServiceTab'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'

const WebsitePage = () => {
  const location = useLocation()
  const id = location.pathname.split('/')[location.pathname.split('/').length - 1]
  const queryClient = useQueryClient()
  const {data: websiteById} = useQuery(`${QUERIES.WEBSITE_BY_ID}-${id}`, () =>
    websitesApi.getWebsiteById(id)
  )

  const invalidateWebsite = () => {
    queryClient.invalidateQueries(`${QUERIES.WEBSITE_BY_ID}-${id}`)
  }

  useQueries([
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
    {queryKey: [QUERIES.WEIGHT_UNITS], queryFn: generalApi.getWeightUnits},
    {queryKey: [QUERIES.SIZE_UNITS], queryFn: generalApi.getSizeUnits},
  ])

  const [currWebsite, setCurrentWebsite] = useState<IWebsite | undefined>(undefined)

  useEffect(() => {
    if (websiteById) {
      setCurrentWebsite(websiteById)
    }
  }, [websiteById])

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <>
      {currWebsite && websiteById ? (
        <Routes>
          <Route
            element={
              <>
                <WebsiteHeader website={currWebsite} />
                <Outlet />
              </>
            }
          >
            {/* <Route
              path={`overview/:id`}
              element={
                <>
                  <WebsiteOverview website={currWebsite} setCurrWebsite={setCurrentWebsite} />
                </>
              }
            /> */}
            <Route
              path={`tos/:locale/:id`}
              element={
                <TermsOfServiceTab website={currWebsite} invalidateWebsite={invalidateWebsite} />
              }
            />
            <Route index element={<Navigate to={`tos/${websiteById.mainLocale}/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default WebsitePage
