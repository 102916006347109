import {Form, Formik, FormikValues} from 'formik'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {countryOverviewSenderSchema} from '../../../countries-list/core/yup/country-page'
import {ICountry, ISenderCountryForm} from '../../../countries-list/core/_models'
import {updateCountry} from '../../../countries-list/core/_requests'
import {InputTemplate} from "../../../../../modules/custom/form-elements/InputTemplate";
import {useQueryClient} from "react-query";
import {useParams} from "react-router-dom";

interface Props {
  currCountry: ICountry
  setCurrCountry: Dispatch<SetStateAction<ICountry | undefined>>
}

const SenderLayout = ({setCurrCountry, currCountry}: Props) => {
  const {locale = 'uk'} = useParams()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const initTranslations =
    currCountry?.translations && currCountry?.translations[locale]
      ? {...currCountry?.translations[locale]}
      : { name: countries[currCountry.country], }

  const initialValues: ISenderCountryForm = {
    ...initTranslations,
    parcelIdentByOrderNumber: currCountry.parcelIdentByOrderNumber === true ? 1 : 0,
    slug: currCountry.slug
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<ISenderCountryForm>, actions: FormikValues) => {
    const {parcelIdentByOrderNumber, slug, name} = values
    setLoading(true)
    let translations = {
      ...currCountry?.translations,
      [locale]: {...initTranslations, ...currCountry?.translations?.locale, name},
    }
    const payload = {
      parcelIdentByOrderNumber:
        parcelIdentByOrderNumber && +parcelIdentByOrderNumber === 1 ? true : false,
      slug,
      translations
    }

    try {
      const response = await updateCountry(currCountry.id, payload)

      if (response) {
        setCurrCountry(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      validationSchema={countryOverviewSenderSchema(intl)}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({values, setFieldValue, errors}) => (
        <Form noValidate className='form'>
          {/* {JSON.stringify(values)} */}
          {/*  {JSON.stringify(errors)} */}
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

              <div className='col-lg-8'>
                <InputTemplate inputName='name' required={true} type='text'/>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Ідентифікація посилок
              </label>
              <div className='col-lg-8'>
                <div className='row flex-nowrap'>
                  <div className='col-lg-6 fv-row me-4'>
                    <CheckboxSwitcher
                      inputName='parcelIdentByOrderNumber'
                      firstOption={intl.formatMessage({
                        id: 'COUNTRY_PAGE.OVERVIEW.ID_SWITCHER.TRACK',
                      })}
                      secondOption={intl.formatMessage({
                        id: 'COUNTRY_PAGE.OVERVIEW.ID_SWITCHER.ORDER',
                      })}
                      value={+values.parcelIdentByOrderNumber}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {' '}
                </div>
              </div>
            </div>
            <div className='row'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Slug</label>

              <div className='col-lg-8'>
                <InputTemplate inputName='slug' required={true} type='text'/>
              </div>
            </div>
          </div>
          <CardFooter loading={loading} success={footerNotification?.success || false}/>
        </Form>
      )}
    </Formik>
  )
}

export default SenderLayout
