import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {ICountry} from '../../countries-list/core/_models'

interface Props {
  country: ICountry
}

export function CountryHeaderNav({country}: Props) {
  const {pathname} = useLocation()
  const {id, locale='uk'} = useParams()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (pathname === `/states/edit/overview/${locale}/${country.id}` && 'active')
            }
            to={`/states/edit/overview/${locale}/${country.id}`}
          >
            Налаштування
            {/* <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' /> */}
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (pathname === `/states/edit/address/${id}` && 'active')
            }
            to={`/states/edit/address/${id}`}
          >
            Структура адреси
            {/* <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' /> */}
          </Link>
        </li>
      </ul>
    </div>
  )
}
