import {Formik, Form} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {updateKey} from '../../core/_requests'
import {ITranslationObject} from '../../../../core/_models'
import PartialPageLoader from '../../../../../../../_metronic/layout/core/PartialPageLoader'
import {CKEInput} from "../../../../../../modules/custom/form-elements/CKEInput";

interface Props {
  translation: ITranslationObject
  invalidateTranslation: () => void
}

export function TranslationTab({translation, invalidateTranslation}: Props) {
  const {locale} = useParams()
  const currLocale = locale || 'uk'

  const translationArray =
    translation?.translations &&
    translation.translations
      .find((item) => item.locale === currLocale)
      ?.value

  const initialValues = {
    translation: translationArray ? translationArray : '',
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {translation: string}) => {
    setLoading(true)

    try {
      const translationsToSend = {
        ...translation,
        translations: [
          ...translation.translations.filter((item) => item.locale !== currLocale),
          {
            locale: currLocale,
            value: values.translation,
          },
        ],
      }

      await updateKey(translationsToSend, translation.id)

      invalidateTranslation()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <div className='card-header pt-5'>
        <p className='fw-bolder'> {translation.keyName}</p>
        <p className='fs-8 text-muted'>{initialValues.translation}</p>
      </div>
      {translation?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-client-tr'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {translation?.translations && (
                  <CKEInput inputName={`translation`} value={values.translation} extended={false} key={currLocale} />
                )}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
