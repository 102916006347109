/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {IStore} from '../../../../core/_models'
import {useMutation, useQueryClient} from 'react-query'
import {IWebsite} from '../../../../../websites/core/_models'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {deleteStore} from '../../../../core/_requests'
import {Portal} from '../../../../../../../_metronic/partials'

type Props = {
  store: IStore
}

const StoreActionsCell: FC<Props> = ({store}: Props) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()

  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === store.website.id)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteStore(store.id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <OverlayTrigger
        placement='left'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/stores/edit/overview/${website?.mainLocale}/${store.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>

      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({
                id: 'STORES.LIST.ACTIONS.TRASH_CONF',
              })}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={store.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <OverlayTrigger
        placement='left'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.TRASH'})}</Tooltip>}
      >
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => setDeleteModalOpen(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </OverlayTrigger>
    </div>
  )
}

export {StoreActionsCell}
