import {Form, Formik} from 'formik'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {IWebsite, ITranslationObject} from '../../../../websites/core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {IWebsitePage} from '../../../website-pages-list/core/_models'
import SerpPreview from 'react-serp-preview'
import {updateKey} from '../../../../websites/elements/TranslationBoard/core/_requests'
import {useState} from 'react'
import {getWeightUnit} from "../../../../delivery-types/core/funcs/getMeasurementUnit";

interface Props {
  page: IWebsitePage
  invalidateData: () => void
  metaKeys: ITranslationObject[]
  website: IWebsite
}
const PageSeo = ({page, invalidateData, metaKeys, website}: Props) => {
  const {locale = 'uk'} = useParams()

  const [activeTab, setActiveTab] = useState('desktop')
  const initialValues = metaKeys?.reduce(
    (prev, curr): any => {
      const metaKeyNameForForm = curr?.keyName
        ?.split('.')
        [curr?.keyName?.split('.').length - 1].split('-')[1]

      return {
        ...prev,
        [metaKeyNameForForm]: curr?.translations?.find((i) => i.locale === locale)?.value,
      }
    },
    {title: '', description: '', noindex: '0', nofollow: '0'}
  )

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: any) => {
    setLoading(true)

    try {
      const promises = metaKeys.map(async (key) => {
        const metaKeyNameForForm = key?.keyName
          ?.split('.')
          [key?.keyName?.split('.').length - 1].split('-')[1]
        const translationsToSend = {
          ...key,
          translations: [
            ...key.translations.filter((item) => item.locale !== locale),
            {locale: locale, value: typeof values[metaKeyNameForForm] === "boolean"?`${+values[metaKeyNameForForm]}`:values[metaKeyNameForForm]},
          ],
        }
        await updateKey(translationsToSend, key.id)
      })

      await Promise.all(promises)

      invalidateData()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-2'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='website-page'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='d-flex justify-content-center'>
                <ul className='nav mb-5'>
                  {' '}
                  {[
                    {value: 'desktop', label: "Комп'ютер"},
                    {value: 'mob', label: 'Мобільний'},
                  ].map((i) => (
                    <li className='nav-item' key={i.value}>
                      <button
                        type='button'
                        onClick={() => {
                          setActiveTab(i.value)
                        }}
                        className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                          activeTab === i.value ? 'active' : ''
                        } fw-bold px-4`}
                      >
                        {i.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='d-flex justify-content-center mb-4'>
                {activeTab === 'mob' && (
                  <div className='border p-3'>
                    <SerpPreview
                      title={values.title || ''}
                      metaDescription={values.description || ''}
                      url={`https://${website?.host}${locale !== 'uk' ? `/${locale}` : ''}/${
                        page.slug
                      }`}
                      width={375}
                    />
                  </div>
                )}
                {activeTab === 'desktop' && (
                  <div className='border p-3'>
                    <SerpPreview
                      title={values.title || ''}
                      metaDescription={values.description || ''}
                      url={`https://${website?.host}${locale !== 'uk' ? `${locale}` : ''}/blog/${
                        page.slug
                      }`}
                      width={600}
                    />
                  </div>
                )}
              </div>
              {metaKeys
                .filter((i) => !i.keyName.includes('meta-keywords'))
                .map((metaItem: any) => {
                  const currentKey = metaItem.keyName
                    .split('.')
                    [metaItem.keyName.split('.').length - 1].split('-')[1]
                  const checkboxOptions = currentKey.startsWith("no")?{
                      title: currentKey,
                      checkboxProps: {
                        // @ts-ignore
                        checked: !!(values[currentKey] && +values[currentKey] === 1),
                        setFieldValue: setFieldValue,
                        value: 1,
                        disabled: false,
                        inputName: currentKey,
                        type: 'checkbox',
                      }
                  }:null;
                  return (
                    <div className='row align-items-center' key={metaItem.id}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        {metaItem.keyName}
                      </label>

                      <div className='col-lg-8'>
                        <InputTemplate
                          {...checkboxOptions}
                          inputName={currentKey}
                          type={currentKey.startsWith("no")?'checkbox':'textarea'}
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PageSeo
