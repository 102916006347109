import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {CountryHeader} from './header/CountryHeader'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {ICountry} from '../countries-list/core/_models'
import {getCountryById} from '../countries-list/core/_requests'
import CountryOverview from './tabs/overview/CountryOverview'
import CountryAddress from './tabs/address/CountryAddress'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const CountryPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: countryById} = useQuery(QUERIES.COUNTRY_BY_ID, () => getCountryById(id))

  const [currCountry, setCurrentCountry] = useState<ICountry | undefined>(undefined)

  useEffect(() => {
    if (countryById) {
      setCurrentCountry(countryById)
    }
  }, [countryById])

  return (
    <>
      {currCountry && countryById ? (
        <Routes>
          <Route
            element={
              <>
                <CountryHeader country={currCountry} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <CountryOverview country={currCountry} setCurrCountry={setCurrentCountry} />
                </>
              }
            />
            <Route
              path={`address/:id`}
              element={
                <>
                  <CountryAddress country={currCountry} setCurrCountry={setCurrentCountry} />
                </>
              }
            />
            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default CountryPage
