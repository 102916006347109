/* eslint-disable jsx-a11y/anchor-is-valid */
import {IStore} from '../../../../core/_models'

type Props = {
  store: IStore
}

const StoreCountriesCell = ({store}: Props) => {
  return (
    <div className='d-flex justify-content-start flex-column'>
      {store.countries.map((country)=> (
        <span className='fw-semobold d-block fs-6 me-1' key={country.id}> {country?.name || ' - '}</span>
      ))}
    </div>
  )
}

export {StoreCountriesCell}
