/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {KTSVG, QUERIES} from '../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {objectToArray} from '../../../../_metronic/helpers/custom/funcs/objectToArray'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {Notification} from '../../../modules/custom/Notification'
import {ICreateNewDirection} from '../core/_models'
import {directionsApi} from '../core/_requests'
import {createDirectionSchema} from '../../../../_metronic/helpers/custom/formik-schemas/direction'
import {CurrencySelect} from '../../../modules/custom/form-elements/CurrencySelect'
import {useListView} from '../core/ListViewProvider'
import ScrollToFieldError from './ScrollToFieldError'
import {CreateDirectionModalHeader} from './CreateDirectionModalHeader'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {IPaymentProvider} from '../../finances/payment-providers/core/_models'

const CreateDirectionForm = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [isLoading, setIsLoading] = useState(false)

  const [createResponse, setCreateResponse] = useState('')
  const weightUnits: string[] = queryClient.getQueryData([QUERIES.WEIGHT_UNITS]) || []

  const sizeUnits: string[] = queryClient.getQueryData([QUERIES.SIZE_UNITS]) || []
  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const getCountries: Record<string, string> | [] = countriesList

  const currenciesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.CURRENCIES_LIST]) || {}

  const paymentProviders: IPaymentProvider[] =
    queryClient.getQueryData([QUERIES.PAYMENT_PROVIDERS_LIST]) || []

  const inits: ICreateNewDirection = {
    countryFrom: '',
    countryTo: '',
    senderTypes: [],
    buyoutCurrency: '',
    minBuyoutPrice: '',
    serviceCurrency: '',
    taxFreeLimit: '',
    taxFreeLimitCurrency: '',
    brokerFee: 0,
    weightUnit: '',
    sizeUnit: '',
    orderPaymentProvider: {
      ...paymentProviders[0],
      value: paymentProviders[0]?.id,
      label: paymentProviders[0]?.name,
    },
    deliveryPaymentProvider: {
      ...paymentProviders[0],
      value: paymentProviders[0]?.id,
      label: paymentProviders[0]?.name,
    },
  }

  const mutation = useMutation(directionsApi.addDirection, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.DIRECTIONS_WITH_IDS_LIST)
      setCreateResponse('success')
      refetch()
    },
    onMutate: () => {
      setCreateResponse('loading')
    },
    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
  })


  const handleSubmit = (values: ICreateNewDirection, actions: FormikValues) => {

    mutation.mutate({
      ...values,
      taxFreeLimit: +values.taxFreeLimit,
      minBuyoutPrice: +values.minBuyoutPrice,
    })

    actions.resetForm()
  }

  return (
    <Formik
      validationSchema={createDirectionSchema}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, errors, setFieldValue, isValid}) => (
        <Form className='form' noValidate id='kt_modal_create_direction_form'>
          <ScrollToFieldError />
          <CreateDirectionModalHeader isLoading={isLoading} isValid={isValid} />
          {/* {JSON.stringify(errors)} */}
          {/* {JSON.stringify(values.store?.id)} */}
          <div
            className='scroll-y modal-body p-0'
            style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
          >
            {' '}
            <div className='p-3 pb-6'>
              {' '}
              <div className='w-100'>
                {getCountries && (
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Страна отправителя</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Укажите страну отправителя'
                      ></i>
                    </label>

                    <Field as='select' name='countryFrom' className='form-select form-select-solid'>
                      <option></option>

                      {objectToArray(getCountries)?.map(({code, name}, index) => (
                        <option value={code} key={index}>
                          {name}
                        </option>
                      ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='countryFrom' />
                    </div>
                  </div>
                )}
                {getCountries && (
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Страна получателя</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Укажите страну получателя'
                      ></i>
                    </label>

                    <Field as='select' name='countryTo' className='form-select form-select-solid'>
                      <option></option>

                      {objectToArray(getCountries)?.map(({code, name}, index) => (
                        <option value={code} key={index}>
                          {name}
                        </option>
                      ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='countryTo' />
                    </div>
                  </div>
                )}
                <Notification
                  noteText='Для отправителя и получателя можно использовать одну и ту же страну, если ваша компания занимается доставкой в пределах одной страны.'
                  borderTop={true}
                />
              </div>
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <div className='w-100'>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                    <span className='required'>Задайте направлению тип</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Выберите тип направления'
                    ></i>
                  </label>

                  <label className='d-flex flex-stack cursor-pointer mb-5'>
                    <span className='d-flex align-items-center me-2'>
                      <span className='symbol symbol-50px me-6'>
                        <span className='symbol-label bg-light-warning'>
                          <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm005.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </span>

                      <span className='d-flex flex-column'>
                        <span className='fw-bolder fs-6'>
                          <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.FORW' />
                        </span>

                        <span className='fs-7 text-muted'>
                          <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.FORW.TOOLTIP' />
                        </span>
                      </span>
                    </span>

                    <span className='form-check form-check-custom form-check-solid'>
                      <Field
                        className='form-check-input'
                        type='checkbox'
                        name='senderTypes'
                        value='1'
                      />
                    </span>
                  </label>

                  <label className='d-flex flex-stack cursor-pointer mb-5'>
                    <span className='d-flex align-items-center me-2'>
                      <span className='symbol symbol-50px me-6'>
                        <span className='symbol-label bg-light-success'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen017.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </span>

                      <span className='d-flex flex-column'>
                        <span className='fw-bolder fs-6'>
                          <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.PERSONAL' />
                        </span>
                        <span className='fs-7 text-muted'>
                          <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.PERSONAL.TOOLTIP' />
                        </span>
                      </span>
                    </span>

                    <span className='form-check form-check-custom form-check-solid'>
                      <Field
                        className='form-check-input'
                        type='checkbox'
                        name='senderTypes'
                        value='2'
                      />
                    </span>
                  </label>
                </div>
                <div className='text-danger'>
                  <ErrorMessage name='senderTypes' />
                </div>
              </div>
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              {' '}
              <div className='d-flex'>
                {' '}
                <div className='fv-row mb-10 w-50 me-6'>
                  {' '}
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Единица измерения веса</span>
                  </label>
                  <Field as='select' name='weightUnit' className='form-select form-select-solid'>
                    <option></option>
                    {weightUnits?.map((item: any, index: any) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <div className='text-danger'>
                    <ErrorMessage name='weightUnit' />
                  </div>
                </div>
                <div className='fv-row mb-10 w-50'>
                  {' '}
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Единица измерения длины</span>
                  </label>
                  <Field as='select' name='sizeUnit' className='form-select form-select-solid'>
                    <option></option>
                    {sizeUnits?.map((item: any, index: any) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <div className='text-danger'>
                    <ErrorMessage name='sizeUnit' />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <InputTemplate
                  inputName={`minBuyoutPrice`}
                  fieldWidth='w-50'
                  disabled={!!itemIdForUpdate}
                  type='number'
                  required={true}
                  title={intl.formatMessage({id: 'DIRECTIONS.ADD_MODAL.FORM.MIN_BUYOUT_PRICE'})}
                  promptText={'Система автоматично блокуватиме викупи меншої вартості.'}
                  promptSign='/media/icons/duotune/general/gen046.svg'
                />
                {values.senderTypes.includes('1') && currenciesList && (
                  <InputTemplate
                    inputName='buyoutCurrency'
                    type='select'
                    required={true}
                    fieldWidth='w-50'
                    title={intl.formatMessage({id: 'DIRECTIONS.ADD_MODAL.FORM.BUYOUT_CURRENCY'})}
                    setFieldValue={setFieldValue}
                    value={values.buyoutCurrency}
                    custom={
                      <CurrencySelect
                        name='buyoutCurrency'
                        currencies={objectToArray(currenciesList)}
                      />
                    }
                  />
                )}
              </div>{' '}
              {currenciesList && (
                <InputTemplate
                  inputName='serviceCurrency'
                  type='select'
                  required={true}
                  fieldWidth='w-50'
                  title={intl.formatMessage({id: 'DIRECTIONS.ADD_MODAL.FORM.SERVICE_CURRENCY'})}
                  setFieldValue={setFieldValue}
                  value={values.serviceCurrency}
                  custom={
                    <CurrencySelect
                      name='serviceCurrency'
                      currencies={objectToArray(currenciesList)}
                    />
                  }
                />
              )}
            </div>{' '}
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <h3>Беспошлинный ввоз</h3>{' '}
              <div className='d-flex flex-gutter w-100'>
                <InputTemplate
                  inputName={`taxFreeLimit`}
                  fieldWidth='w-50'
                  disabled={!!itemIdForUpdate}
                  type='number'
                  required={true}
                  title={intl.formatMessage({id: 'DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_LIMIT'})}
                  promptText={intl.formatMessage({
                    id: 'DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_LIMIT.TOOLTIP',
                  })}
                  promptSign='/media/icons/duotune/general/gen044.svg'
                />

                {currenciesList && (
                  <InputTemplate
                    inputName='taxFreeLimitCurrency'
                    type='select'
                    required={true}
                    fieldWidth='w-50'
                    title={intl.formatMessage({id: 'DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_CURRENCY'})}
                    setFieldValue={setFieldValue}
                    value={values.taxFreeLimitCurrency}
                    custom={
                      <CurrencySelect
                        name='taxFreeLimitCurrency'
                        currencies={objectToArray(currenciesList)}
                      />
                    }
                  />
                )}
              </div>
              <InputTemplate
                inputName={`brokerFee`}
                fieldWidth='w-50'
                disabled={!!itemIdForUpdate}
                type='number'
                required={true}
                title={intl.formatMessage({id: 'DIRECTIONS.ADD_MODAL.FORM.BROKER_FEE'})}
                value={values.brokerFee}
                numberWithControls={true}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {CreateDirectionForm}
